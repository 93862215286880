require("../scss/header.sass")

document.addEventListener("turbolinks:load", () => {
  $('#desktop-dropdown-toggle').click(function() {
    $('#desktop-dropdown-menu').toggleClass('d-none')
    $('#desktop-dropdown-toggle').toggleClass('active')
  });

  $('.navbar-toggler').click(function(e) {
    $('#mobile-menu').toggleClass('d-none')
  });

  $('#mobile-dropdown-toggle').click(function() {
    $('#mobile-dropdown-menu').toggleClass('d-none')
    $('#mobile-dropdown-toggle').toggleClass('active')
  });
});
